import { roundToOneDecimal } from './roundToOneDecimal';

export const displayMoney = (value: number): string => {
  // one can hope
  if (value > 10 ** 9) {
    return `${roundToOneDecimal(value / 10 ** 9)}G€`;
  }
  if (value > 10 ** 6) {
    return `${roundToOneDecimal(value / 10 ** 6)}M€`;
  }
  if (value > 10 ** 3) {
    return `${roundToOneDecimal(value / 10 ** 3)}K€`;
  }
  return `${roundToOneDecimal(value)}€`;
};
