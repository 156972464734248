import React from 'react';

import classNames from 'classnames';

type Props = {
  winners: [string, string, number];
  losers: [string, string, number];
  date: string;
};

export const FloatingScore: React.FC<Props> = ({ winners, losers, date }) => {
  const members = winners.slice(0, -1) as string[];
  const score = winners.slice(-1)[0] as number;

  const members2 = losers.slice(0, -1) as string[];
  const score2 = losers.slice(-1)[0] as number;
  return (
    <div className="flex justify-between huge-score">
      <span>{date}</span>
      {members.map((name, index) => (
        <span
          key={name}
          className={classNames('px-3', losers && name.toLowerCase().split(' ').join('-'))}
        >
          {index === 1 && ' - '}
          {name}
        </span>
      ))}
      <span className="px-3">{score}</span>
      <span className="px-3">{score2}</span>
      {members2.map((name, index) => (
        <span
          key={name}
          className={classNames('px-3', losers && name.toLowerCase().split(' ').join('-'))}
        >
          {index === 1 && ' - '}
          {name}
        </span>
      ))}
    </div>
  );
};
