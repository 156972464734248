import { useQuery } from '@tanstack/react-query';

import { request } from '@travauxlib/shared/src/utils/request';

import { baseUrl } from 'config';
import { SlackEvent } from 'utils/SlackEvent';

export const SLACK_EVENTS_KEY = 'slack-events';

export const useSlackEvent = (): { isLoading: boolean; slackEvent?: string } => {
  const { isLoading, data } = useQuery<{
    event: SlackEvent;
  }>({
    queryKey: [SLACK_EVENTS_KEY],
    queryFn: () => request(`${baseUrl}/slackbot/events`),
    refetchInterval: 1000,
  });

  return { isLoading, slackEvent: data?.event || undefined };
};
