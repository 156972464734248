/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';

import { useGoogleLogin, googleLogout } from '@react-oauth/google';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { request } from '@travauxlib/shared/src/utils/request';

import { baseUrl } from './config';

const handleLogin = async ({ access_token }: { access_token: string }) => {
  const requestURL = `${baseUrl}/admin-login`;
  return request(requestURL, {
    method: 'POST',
    body: {
      idToken: access_token,
    },
  });
};

export const useLogin = () => {
  const queryClient = useQueryClient();
  const { mutateAsync: login } = useMutation({
    mutationFn: handleLogin,
    onSuccess: resp => {
      queryClient.setQueryData(['AUTH'], resp);
    },
  });

  return login;
};

const handleLogout = () => {
  const requestURL = `${baseUrl}/admin-logout`;
  googleLogout();
  return request(requestURL, {
    method: 'POST',
  });
};

export const useLogout = () => {
  const queryClient = useQueryClient();
  const { mutateAsync: login } = useMutation({
    mutationFn: handleLogout,
    onSuccess: () => {
      queryClient.setQueryData(['AUTH'], { isLoggedIn: false });
    },
  });

  return login;
};

export const LoginButton: React.FC = () => {
  const onLoginSuccess = useLogin();
  const login = useGoogleLogin({
    onSuccess: onLoginSuccess,
    // onError: onLoginFailure,
    hosted_domain: 'hemea.com',
  });

  return <Button onClick={() => login()}>Se connecter</Button>;
};

export const LogoutButton: React.FC = () => {
  const logout = useLogout();
  return (
    <>
      <Button onClick={() => logout()}>Se déconnecter</Button>
    </>
  );
};
