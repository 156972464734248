export enum SlackEvent {
  BabyLose = '10 0',
  NewChantier = 'NOUVEAU CHANTIER',
  Ludo = 'LUDO',
  Nana = 'NANA',
  Release = 'RELEASE',
  Zgeg = 'oula ça a zgegué',
  Dev = 'developers',
  Alexandre = 'alexandre',
  BURIN = 'LE BURIN',
  LudoLaPince = 'ludo la pince',
  TNUL = 'TNUL',
  SixtineCafard = 'sixtine cafard',
  Gogol = 'ALERTE AU GOGOL',
  Pau = 'Pau',
}

export const eventToYoutubeSettings: {
  [K in SlackEvent]: {
    isRefresh?: boolean;
    videoId: string;
    startSeconds?: number;
    endSeconds?: number;
  };
} = {
  [SlackEvent.BabyLose]: { videoId: '_jGnz6_2C80', startSeconds: 0, endSeconds: 7 },
  [SlackEvent.NewChantier]: { videoId: '0us2UbWFTCI', startSeconds: 42, endSeconds: 52 },
  [SlackEvent.Ludo]: { videoId: 'IHkRjn8XVtw', startSeconds: 0, endSeconds: 1 },
  [SlackEvent.Nana]: { videoId: '9SfjOlHd9ZE', startSeconds: 19.3, endSeconds: 20 },
  [SlackEvent.Release]: { videoId: '69AyYUJUBTg', startSeconds: 0 },
  [SlackEvent.Zgeg]: { isRefresh: true, videoId: 'AlexZgeg' },
  [SlackEvent.Dev]: { videoId: 'Vhh_GeBPOhs', startSeconds: 0, endSeconds: 5 },
  [SlackEvent.Alexandre]: { videoId: '6R67M-ZUGdo', startSeconds: 0, endSeconds: 3 },
  [SlackEvent.BURIN]: { videoId: 'jKEAsQwYiyY', startSeconds: 0, endSeconds: 2 },
  [SlackEvent.LudoLaPince]: { videoId: '64YoLUk_zi4', startSeconds: 34, endSeconds: 37 },
  [SlackEvent.TNUL]: { videoId: '1bieotMBTP8', startSeconds: 0, endSeconds: 5 },
  [SlackEvent.SixtineCafard]: { videoId: '2apJXX46ce0', startSeconds: 3, endSeconds: 10 },
  [SlackEvent.Gogol]: { videoId: '0uaQMxBjd5E', startSeconds: 0, endSeconds: 3 },
  [SlackEvent.Pau]: { videoId: '5-tuuD2RpoA', startSeconds: 89.6, endSeconds: 91.5 },
};
