import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { useInterval } from 'react-use';
import PlayerFactory from 'youtube-player';
import { YouTubePlayer } from 'youtube-player/dist/types';

import lavelinge from 'lavelinge.png';
import { eventToYoutubeSettings, SlackEvent } from 'utils/SlackEvent';

import { useSlackEvent } from './api/useSlackEvent';
import cartoonHand from './assets/cartoon-hand.jpeg';

export const YoloSlack: React.FC = () => {
  const [top, setTop] = useState('0px');
  const [player, setPlayer] = useState<YouTubePlayer | undefined>(undefined);
  const [showPlayer, setShowPlayer] = useState<boolean>(false);
  const { slackEvent } = useSlackEvent();

  useInterval(() => {
    setTop(top => (top === '0px' ? '100px' : '0px'));
  }, 500);

  useEffect(() => {
    setPlayer(PlayerFactory('video-player', { width: '100%', height: '100%' }));
  }, []);

  useEffect(() => {
    if (player) {
      player.on('stateChange', event => {
        // Video ended
        if (event.data === 0) {
          setShowPlayer(false);
        }
        // Video playing
        if (event.data === 1) {
          setShowPlayer(true);
        }
      });
    }
  }, [player]);

  useEffect(() => {
    if (slackEvent) {
      const settings = eventToYoutubeSettings[slackEvent as SlackEvent];
      if (settings.isRefresh) {
        location.reload();
      }
      player?.loadVideoById(settings);
      setShowPlayer(true);
    }
  }, [slackEvent]);

  return (
    <>
      <div className={classNames('w-screen h-screen', { hidden: !showPlayer })}>
        <div id="video-player" />
      </div>
      {!showPlayer && (
        <div className="bg-neutral-0">
          <div
            // eslint-disable-next-line react/forbid-dom-props
            style={{
              top,
            }}
            className="transition-[500ms] w-screen h-screen relative"
          >
            <div className="absolute animate-orbit top-1/4 left-1/5">
              <img className="w-[100px] h-[100px] -scale-x-100" src={cartoonHand} />
            </div>
            <div className="absolute left-1/2 -translate-x-1/2 top-[10rem] rounded-full">
              <img
                className="relative top-[-136px] left-[8px]"
                src={lavelinge}
                width="600"
                height="600"
              />
            </div>
            <div className="absolute left-1/2 -translate-x-1/2 top-[10rem] rounded-full overflow-hidden">
              <img
                className="animate-spin"
                src="https://recette.images.hemea.com/employees/person/227c58cc-f10e-4fdf-908f-53d716c079ff/picture/Official?auto=format&fit=crop&crop=top&h=250&w=250"
              />
            </div>
            <div
              className="absolute animate-orbit top-1/4 left-3/4"
              // eslint-disable-next-line react/forbid-dom-props
              style={{ animationDelay: '800ms' }}
            >
              <img className="w-[100px] h-[100px]" src={cartoonHand} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
